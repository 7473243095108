<script setup>
import { usePlaningStore } from '@/stores/planning';


import Date from './Date.vue';
const props = defineProps({
    shiftCountsByDate: Object,
});
const store = usePlaningStore();
</script>

<template>
    <div class="grid grid-cols-7 gap-3 py-1 mb-2 text-sm font-semibold bg-white text-blue">
        <div class="flex-center">{{ $t('Mo') }}</div>
        <div class="flex-center">{{ $t('Tu') }}</div>
        <div class="flex-center">{{ $t('We') }}</div>
        <div class="flex-center">{{ $t('Th') }}</div>
        <div class="flex-center">{{ $t('Fr') }}</div>
        <div class="flex-center">{{ $t('Sa') }}</div>
        <div class="flex-center">{{ $t('Su') }}</div>
    </div>
    <div v-for="(dates, week) in store.calDatesPerWeek" :key="week" class="grid grid-cols-7 gap-1 mb-2">
        <template v-for="date in dates" :key="date.format()">
            <Date :date="date" :selected="date.format('YYYY-MM-DD') == store.selectedDateString"
                :open="shiftCountsByDate[date.format('YYYY-MM-DD')]?.open ?? null"
                :total="shiftCountsByDate[date.format('YYYY-MM-DD')]?.total ?? null" />
        </template>
    </div>
</template>